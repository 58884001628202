.sidebar-button {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 15px;
  position: relative;
  transition: all 0.4s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }


  &::before {
    top: 7px;
    position: absolute;
    left: 2px;
    width: 26px;
    height: 3px;
    content: "";
    transition: transform .3s;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    background: rgb(0, 0, 0);
    transform: translateY(1px) rotate(-45deg) scaleX(.6);
  }

  &::after {
    bottom: 7px;
    position: absolute;
    left: 2px;
    width: 26px;
    height: 3px;
    content: "";
    transition: transform .3s;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    background: rgb(0, 0, 0);
    transform: translateY(-2px) rotate(45deg) scaleX(.6);
  }

  &_theme_white {
    &::before {

      background: rgb(255, 255, 255);

    }

    &::after {

      background: rgb(255, 254, 254);

    }
  }

  &_type_open {
    &::after {
      bottom: 7px;
      transform: none;
    }

    &::before {
      top: 8px;
      transform: none;
    }
  }
}