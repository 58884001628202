.logo {
  width: 230px;
  object-fit: contain;
  transition: transform 0.4s, opacity 0.4s linear;

  &_type_profile {
    width: 150px;
  }

  &:hover {
    transform: scale(1.03);
    opacity: 0.7;
  }
}

.logo-link {
  display: flex;
}

@media screen and (max-width: 1100px) {
  .logo {
    width: 200px;

    &_type_profile {
      width: 150px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .logo {
    width: 180px;

    &_type_profile {
      width: 150px;
    }
  }
}

@media screen and (max-width: 900px) {
  .logo {
    width: 170px;

    &_type_profile {
      width: 150px;
    }
  }
}

@media screen and (max-width: 700px) {
  .logo {
    width: 160px;

    &_type_profile {
      width: 150px;
    }
  }
}