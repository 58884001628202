.module-equipments {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  margin-left: 35px;

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.065em;
    color: #BDBDBD;
    margin-bottom: 10px;
  }

  &__item {
    position: relative;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.065em;
    color: #BDBDBD;
    padding: 8px 0;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      left: -20px;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #FEAE62;
      border-radius: 50%;
    }
  }

  &__line {
    width: 75%;
    height: 1px;
    background-color: #FEAE62;
    border: none;
    margin: 50px 0 0 -50px;
  }
}

@media screen and (max-width: 800px) {
  .module-equipments {
    margin-left: 25px;
    width: 95%;
  }

  .module-equipments__item:after {
    left: -10px;
  }

  .module-equipment__line {
    margin-top: 25px;
  }
}

@media screen and (max-width: 550px) {
  .module-equipments__title {
    font-size: 14px;
  }

  .module-equipments__item {
    font-size: 12px;
  }

  .module-equipment__line {
    margin-top: 15px;
  }
}