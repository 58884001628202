@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('Montserrat-Regular.woff2') format('woff2'),
    url('Montserrat-Regular.woff') format('woff'),
    url('Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('Montserrat-Bold.woff2') format('woff2'),
    url('Montserrat-Bold.woff') format('woff'),
    url('Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Black.eot');
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url('Montserrat-Black.eot?#iefix') format('embedded-opentype'),
    url('Montserrat-Black.woff2') format('woff2'),
    url('Montserrat-Black.woff') format('woff'),
    url('Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('Montserrat-Medium.woff2') format('woff2'),
    url('Montserrat-Medium.woff') format('woff'),
    url('Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-ExtraBold.eot');
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('Montserrat-ExtraBold.woff2') format('woff2'),
    url('Montserrat-ExtraBold.woff') format('woff'),
    url('Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-SemiBold.eot');
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('Montserrat-SemiBold.woff2') format('woff2'),
    url('Montserrat-SemiBold.woff') format('woff'),
    url('Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}