.auth-button {
  height: 100%;
  display: flex;
  align-items: center;
  transition: opacity 0.4s linear;

  &__signin {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 105%;
    letter-spacing: 0.01em;
    cursor: pointer;
    background-color: transparent;
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 10px;
    border-radius: 7px;
    padding: 9px 12px 7px 28px;

    &_theme_mobile {
      width: 96px;
      max-height: 40px;
      height: 40px;
      border: 1px solid #FFB570;
      color: #000;
      font-weight: 600;
      margin: 20px 0;
    }

    &_theme_default {
      max-height: 38px;
      width: 96px;
      margin-left: 30px;
      border: 1px solid #BDBDBD;
      color: #FFB570;
    }
  }
}