.popup-state {
  position: fixed;
  top: 10px;
  right: 25px;
  transition: all 0.8s ease;
  display: flex;
  width: calc(100% - 38px);
  max-width: 430px;
  min-height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #000;
  background-color: rgb(173, 173, 173);
  padding: 5px 9px;
  z-index: 1100;
  border-radius: 13px;

  &_type {
    &_visible {
      transform: translateX(0);
    }

    &_hidden {
      transform: translateX(200%);
    }
  }

  &__image {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }

  &__messages {
    max-width: 80%;
    font-size: 12px;
    line-height: 110%;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgb(17, 17, 17);
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 18px;
    height: 18px;
    transition: opacity 0.4s, transform 0.4s linear;
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;

    &:hover {
      opacity: 1;
    }

    &:before,
    &:after {
      position: absolute;
      left: 7px;
      content: ' ';
      height: 19px;
      width: 2px;
      border-radius: 3px;
      background-color: #333;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:hover {
      opacity: 0.8;
    }
  }
}