.not-found {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  height: calc(100vh - 24px);
  padding: 12px 25px;
  align-items: center;

  &__logo {
    justify-content: flex-start;
    display: flex;
    width: 100%;
  }

  &__title {
    font-weight: 400;
    font-size: 140px;
    line-height: 169px;
    text-align: center;
    margin-top: 180px;
    color: #fff;
    cursor: default;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #fdad62;
    margin-top: 10px;
    cursor: default;
  }

  &__button {
    width: 25%;
    min-width: 260px;
    height: 35px;
    margin-top: 170px;
    border: none;
    color: #fff;
    border-radius: 20px;
    background-color: #dd9a5a;
    cursor: pointer;
    transition: opacity 0.4s linear;

    &:hover {
      opacity: 0.7;
    }
  }

}

@media screen and (max-width: 360px) {
  .not-found__title {
    font-size: 110px;
    line-height: 139px;
  }

  .not-found__subtitle {
    font-size: 13px;
    line-height: 15px;
  }

  .not-found__button {
    min-width: 210px;
  }
}