.navigation {
  display: flex;

  &__list {
    display: flex;

    &_type_row {
      flex-direction: row;
    }

    &_type_col {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  &__list_element {
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.05em;

    &_type_row {
      padding: 10px;
    }

    &_type_col {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }

  &__link {
    color: #E9E9E9;
    transition: all 0.4s linear;

    &_type_row {
      padding: 10px 0;
    }

    &_type_col {
      color: #000;
      font-weight: 500;
      width: 50%;
      text-align: center;
      padding: 15px;
    }

    &_type_active-row {
      color: #FFB570;
      border-bottom: 1px solid #FFB570;
    }

    &_type_active-col {
      color: #FFB570;
      border-bottom: 1px solid #FFB570;
    }

    &:hover {
      color: rgb(255, 181, 112);
      color: linear-gradient(189deg, rgba(255, 181, 112, 1) 0%, rgba(233, 236, 240, 1) 28%, rgba(162, 164, 167, 1) 63%, rgba(255, 255, 255, 1) 100%);
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: 1160px) {
  .navigation__list_element {
    font-size: 15px;
    line-height: 105%;
    letter-spacing: 0.03em;

    &_type_row {
      padding: 5px;
    }
  }
}

@media screen and (max-width: 1050px) {
  .navigation__list_element {


    &_type_row:first-of-type {
      display: none;
    }
  }
}