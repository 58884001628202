.module-slider {
  width: 600px;
  height: 400px;
  border-radius: 15px;
  margin: 0 !important;
  cursor: pointer;
  --swiper-navigation-color: rgb(0, 0, 0);
  --swiper-pagination-color: rgb(0, 0, 0);
  --swiper-navigation-size: 26px;


  &__slide {
    height: 100%;
    max-height: 90%;
    width: 100%;
    border: none;
    object-fit: contain;
  }
}

@media screen and (max-width: 1440px) {
  .module-slider {
    width: 400px;
  }
}

@media screen and (max-width: 1000px) {
  .module-slider {
    width: 100%;
    height: 400px;
  }
}


@media screen and (max-width: 800px) {
  .module-slider {
    width: 100%;
    height: 300px;
  }
}