.module-descriptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 35px 0 35px 35px;
  width: 100%;
  max-width: calc(50% - 35px);
  list-style: none;

  &__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.065em;
    color: #000000;
    margin-bottom: 10px;
  }

  &__item {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.065em;
    color: #000000;
    padding: 8px 0;
    width: 100%;

  }
}

@media screen and (max-width: 950px) {
  .module-descriptions {
    padding: 45px 0 0 0;
    width: 100%;
    max-width: 80%;

    &__title {
      font-size: 34px;
      font-weight: 700;
      line-height: 140%;
    }
  }
}

@media screen and (max-width: 550px) {
  .module-descriptions {
    max-width: 95%;

    &__title {
      font-size: 28px;
      font-weight: 600;
      line-height: 130%;
    }

    &__item {
      font-size: 14px;
    }
  }
}