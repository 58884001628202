.profile-preview {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 65px;

  &__title {
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.04em;
    line-height: 110%;
  }
}

@media screen and (max-width: 500px) {
  .profile-preview__title {
    font-size: 18px;
  }
}