.burger-button {
  width: 28px;
  height: 23px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  text-align: left;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #BDBDBD;
  border-top: 3px solid #BDBDBD;
  transition: opacity 0.4s linear;
}

.burger-button:hover {
  opacity: 0.6;
}

.burger-button__line {
  width: 100%;
  height: 3px;
  background-color: #BDBDBD;
}


@media screen and (max-width: 450px) {
  .burger-button {
    width: 22px;
    height: 20.5px;
    border-bottom: 2.5px solid #BDBDBD;
    border-top: 2.5px solid #BDBDBD;
  }

  .burger-button__line {
    height: 2.5px;
  }
}