.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(16, 16, 16, 0.87);
  color: #fff;

  &_type_auth {
    background-color: #fff;
    color: #000;
  }

  &_type_profile {
    background-color: #fff;
    color: #000;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &_type_terms {
    background-color: #fff;
    color: #000;
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;

  &_type_not-found {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &_type_auth {
    max-width: 100%;
    position: relative;
    justify-content: center;
  }

  &_type_contact {
    justify-content: flex-start;
  }

  &_type_profile {
    max-width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: #252836;
    border-radius: 25px 0 0 25px;
  }

  &_type_product {
    max-width: 100%;
  }

  &__head {
    width: calc(100% - 100px);
    max-width: 1440px;
    padding: 15px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  &_type_promo {
    height: calc(100vh - 84px);
    max-height: 1280px;
  }

  &_type_contact {
    min-height: calc(100vh - 84px);
    max-width: 1540px;
  }

  &_type_profile {
    align-items: flex-start;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
  }

  &_type_module-facts {
    background-color: #dea066;
  }

  &_type_module-promo {
    background-color: #e9e9e9;
  }

  &__content {
    display: flex;
    position: relative;
    width: 100%;
    height: calc(100% - 100px);
    max-width: calc(100% - 100px);
    padding: 50px;

    &_type_promo {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &_type_about {
      max-width: calc(1540px - 100px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &_type_header-auth {
      height: calc(100% - 75px);
      padding-top: 25px;
      max-width: calc(1540px - 100px);
      justify-content: space-between;
      align-items: center;
    }

    &_type_auth {
      justify-content: center;
      align-items: center;
    }

    &_type_contact {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }

    &_type_terms {
      width: 100%;
      max-width: 100%;
      padding: 0 0 40px 0;
      flex-direction: column;
      align-items: center;
    }

    &_type_profile {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    &_type_module-promo {
      justify-content: space-around;
      width: calc(1920px - 100px);
    }

    &_type_module-facts {
      width: 100%;
      max-width: 1920px;
      padding: 0;
    }

    &_type_module-tables {
      width: 100%;
      max-width: 1920px;
      padding: 35px 0;
    }

    &_type-not-found {}
  }
}

@media screen and (max-width: 1541px) {
  .section__content_type_header-auth {
    max-width: calc(100% - 100px);
  }
}

@media screen and (max-width: 1070px) {
  .section__content_type_module-promo {
    justify-content: space-between;
  }
}

@media screen and (max-width: 950px) {
  .section__content_type_contact {
    flex-direction: column;
  }

  .main_type_profile {
    border-radius: 0;
  }

  .section__content_type_module-promo {
    flex-direction: column;
    align-items: center;
  }

  .section__content_type_profile {
    padding-top: 5px;
  }
}

@media screen and (max-width: 870px) {
  .section__content {
    max-width: calc(100% - 80px);
    height: calc(100% - 80px);
    padding: 40px;

    &_type_terms {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    &_type_module-facts {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    &_type_module-tables {
      width: 100%;
      max-width: 1920px;
      padding: 25px 0;
    }

    &_type_profile {
      height: calc(100vh - 40px);
      padding-top: 0;
    }

  }
}

@media screen and (max-width: 800px) {}

@media screen and (max-width: 490px) {
  .section__content {
    max-width: calc(100% - 50px);
    height: calc(100% - 40px);
    padding: 20px 25px;

    &_type_terms {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    &_type_module-facts {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    &_type_module-tables {
      width: 100%;
      max-width: 1920px;
      padding: 25px 0;
    }


    &_type_profile {
      height: calc(100vh - 20px);
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 320px) {
  .section__content {
    max-width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;

    &_type_terms {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    &_type_module-facts {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    &_type_module-tables {
      width: 100%;
      max-width: 1920px;
      padding: 25px 0;
    }

    &_type_profile {
      height: calc(100vh - 10px);
      padding-top: 0;
    }
  }
}