.close-button {
  position: relative;
  width: 32px;
  height: 32px;
  transition: opacity 0.4s, transform 0.4s linear;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    border-radius: 3px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
}