.promo {

  &__title {
    cursor: default;
    font-weight: 600;
    font-size: 50px;
    line-height: 115%;
    letter-spacing: 0.02em;
    width: calc(100% - 100px);
    max-width: 1820px;
    padding: 0 0 30px 0;
  }

  &__subtitle {
    width: calc(100% - 100px);
    max-width: 1820px;
    cursor: default;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0.02em;
    padding-bottom: 100px;
  }

  &__subtitle::selection {
    background-color: transparent;
  }

  &__title::selection {
    background-color: transparent;
  }
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  canvas {
    position: absolute !important;
  }

}

@media screen and (max-width: 1520px) {
  .promo {
    &__title {
      width: 100%;
    }

    &__subtitle {
      width: 100%;
    }
  }
}

@media screen and (max-width: 870px) {
  .promo {
    &__title {
      width: 100%;
      font-size: 40px;
    }

    &__subtitle {
      width: 100%;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 480px) {
  .promo {
    &__title {
      width: 100%;
      font-size: 30px;
      padding-bottom: 15px;
    }

    &__subtitle {
      width: 100%;
      font-size: 12px;
      line-height: 105%;
    }
  }
}

@media screen and (max-width: 300px) {
  .promo {
    &__title {
      font-size: 24px;
      padding-bottom: 15px;
    }
  }
}