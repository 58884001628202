.module-tabs {
  width: 100%;

  &__list {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    padding: 0 33px;
    border-bottom: 1px solid rgba(254, 173, 98, 1)
  }

  &__items {
    width: calc(100% - 50px);
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__list-item {
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 0.04em;
    line-height: 125%;
    font-weight: 600;
    margin-right: 12px;
    padding: 8px;
    border-radius: 6px 6px 0 0;
    transition: background-color 0.4s, color 0.4s ease-in;

    &_type_active {
      background-color: #fff;
      color: rgb(0, 0, 0);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

.module-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 70%;

  &__item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid rgba(254, 173, 98, 0.5);
    transition: background-color 0.2s linear;

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-bottom: none;
      border-radius: 0 0 4px 4px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &__key {
    font-size: 16px;
    letter-spacing: 0.03em;
    line-height: 110%;
    font-weight: 600;
  }

  &__value {
    font-size: 16px;
    letter-spacing: 0.03em;
    line-height: 110%;
    font-weight: 600;
    text-align: right;
  }
}

@media screen and (max-width: 830px) {
  .module-table {
    max-width: 85%;


    &__key {
      font-size: 14px;
      letter-spacing: 0.02em;

      font-weight: 500;
    }

    &__value {
      font-size: 14px;
      letter-spacing: 0.02em;
      font-weight: 500;
    }
  }
}


@media screen and (max-width: 610px) {
  .module-table {
    max-width: 95%;
  }
}

@media screen and (max-width: 480px) {
  .module-table {
    max-width: 98%;

    &__item {
      padding: 25px 10px;
    }
  }

  .module-tabs {
    width: 100%;

    &__list {
      padding-left: 13px;
      padding-right: 13px;
    }

    &__items {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    &__list-item {
      cursor: pointer;
      font-size: 14px;
      letter-spacing: 0.02em;
      line-height: 115%;
      font-weight: 500;
      margin-right: 8px;
      padding: 6px;
    }
  }
}

@media screen and (max-width: 370px) {
  .module-table {
    max-width: 98%;

    &__key {
      font-size: 12px;
    }

    &__value {
      font-size: 12px;

    }
  }

  .module-tabs {
    width: 100%;

    &__list {
      padding-left: 5px;
      padding-right: 5px;
    }

    &__list-item {
      cursor: pointer;
      font-size: 10px;
      letter-spacing: 0.02em;
      line-height: 105%;
      margin-right: 4px;
      padding: 4px;
    }
  }
}