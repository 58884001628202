.profile-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  max-width: 250px;
  min-width: 250px;
  background-color: #fff;
  transition: width 0.3s ease;

  &__lens {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 97;

    &_type_active {
      display: flex;
    }
  }

  &__items-lens {
    width: 100%;
    height: 100vh;
    max-width: 350px;
    background-color: #fff;
    z-index: 99;
  }

  &__items {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 100;
    height: 100%;
  }

  &_type_active {
    width: 100%;
  }

  &__head {
    display: flex;
    width: calc(100% - 20px);
    padding: 0 10px 0 10px;
    justify-content: space-between;
    align-items: center;
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 25px 0;
  }

  &__title {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    letter-spacing: 0.02em;
    font-weight: 600;
    line-height: 105%;
    width: calc(100% - 23px);
    padding: 0 0 15px 23px;
    transition: opacity 0.6s, visability 0.6s linear;

    &_type_hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 20px;
    border: none;
    min-height: 30px;

    &-title {
      overflow-y: hidden;
      color: #000;
      font-size: 14px;
      font-weight: 600;
      line-height: 106%;
      letter-spacing: 0.03em;
      transition: opacity 0.6s, visability 0.6s, width 0.6s, background-color 0.4s linear;

      &_type_hidden {
        width: 0;
        opacity: 0;
        visibility: hidden;
      }
    }

    &_type_active {
      border-left: 3px solid red;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &__link-icon {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center;
    margin-right: 10px;

  }

  &__bottom {
    width: calc(100% - 40px);
    padding: 0 20px 20px;
    display: flex;
  }

  &__logout {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    width: 100%;
    height: 40px;
    border: none;
    cursor: pointer;
    border: 1px solid #2d303e;
    padding: 0 10px;
    transition: all 0.6s linear;

    &:hover {
      border-color: rgb(250, 112, 96);
    }


    &-icon {
      display: flex;
      width: 40px;
      height: 40px;
      background-color: transparent;
      background-size: 20px 20px;
      background-position: center;
      background-repeat: no-repeat;
    }

    &-title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.02em;
      min-height: 20px;
      transition: display 1.2s linear;

      &_type_hidden {
        display: none;
      }
    }

    &_type_hidden {
      border: none;
    }
  }


  &_type_hidden {
    min-width: 60px;
    overflow: hidden;

    .profile-bar__link-icon {
      margin-right: 0;
    }

    .profile-bar__link {
      padding: 10px;
    }

    .profile-bar__head {
      width: 100%;
      padding: 0;
      margin: 0;
    }

    .profile-bar__bottom {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin: 0;
    }
  }

}

@media screen and (max-width: 950px) {
  .profile-bar {
    width: 100vh;
    height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-200%);
    z-index: 97;
    align-items: flex-start;
    background-color: transparent;

    &_type_active {
      transform: translateX(0);
    }

    &__items {
      width: 100%;
      max-width: 350px;
      max-height: 700px;
      background-color: #fff;
    }
  }
}


@media screen and (max-width: 470px) {
  .profile-bar {

    &__items {
      width: 100%;
      max-width: 100%;
    }

    &__items-lens {
      width: 100%;
      max-width: 100%;
    }

  }
}