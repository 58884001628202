.form {

  &__label {
    font-size: 10px;
    line-height: 110%;
    color: #000;

    &_type_terms {
      width: 100%;
      display: flex;
      padding-left: 5px;
    }

    &_theme_white {
      color: #fff;
    }
  }


  &__input {
    display: inline-flex;
    border: none;
    border-bottom: 1px solid #E8E8E8;
    margin: 5px 0 0;
    padding: 0 0 10px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-rendering: optimizeLegibility;
    background-color: transparent;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:focus {
      outline: none;
      border-bottom: 1px solid #3456F3;
    }

    &_theme_bold:focus {
      font-weight: 500;
    }

    &_theme_white {
      color: #fff;
    }

    &_theme_error,
    &_theme_error:focus {
      color: #EE3465;
      border-bottom: 1px solid #EE3465;
    }
  }

  &__textarea {
    color: #fff;
    display: inline-flex;
    border: none;
    border-bottom: 1px solid #E8E8E8;
    background-color: transparent;
    color: #fff;
    min-height: 167px;
    max-height: 200px;
    width: 100%;
    transition: all 0.4s linear;
    margin: 5px 0 0;
    padding: 0 0 10px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    resize: vertical;

    &_theme_error {
      color: #EE3465;
      border-bottom: 1px solid #EE3465;
    }

    &:focus {
      outline: none;
      border-bottom: 1px solid #3456F3;
    }
  }

  &__drop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    background-color: transparent;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    padding: 20px;
    border: 1px dashed #eaeaeb;
    cursor: pointer;


    &:hover {
      opacity: 0.6;
      border: 1px dashed #6969df;
    }

    &-title {
      width: 80%;
      font-size: 12px;
      letter-spacing: 0.02em;
      font-weight: 500;
      line-height: 110%;
      text-align: center;
    }
  }

  &__box-drop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
  }




  &__error {
    display: flex;
    align-items: center;
    visibility: hidden;
    min-height: 24px;
    margin-bottom: 6px;
    font-size: 10px;
    line-height: 12px;
    color: #EE3465;

    &-avatar {
      text-align: center;
    }

    &_type_visible {
      visibility: visible;
    }
  }

  &-profile {
    &__input {
      background-color: #2d303e;
      border: 1px solid #2b2e3c;
      border-radius: 7px;
      padding: 10px 12px;
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.03em;
      line-height: 110%;
      color: #d0d2d5;
      margin: 7px 0;


      &_type_email {
        justify-content: space-between;
        align-items: center;

      }

      &:focus {
        outline: none;
        border-color: #696b73;
      }

      &::placeholder {
        color: #d0d2d5bb;
      }

      &:focus::placeholder {
        visibility: hidden;
      }
    }

    &__label {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: #c8c9cd;
      margin: 4px 45px 4px 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}



@media screen and (max-width: 1280px) {
  .form {
    &__drop {

      width: 200px;
      height: 200px;

    }

    &__box-drop {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 250px;
    }
  }
}

@media screen and (max-width: 950px) {

  .form-profile {
    &__label {
      margin: 4px 0 4px 0;

    }

  }
}