.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 570px;
  padding: 30px 0;

  &-check-input {
    cursor: pointer;
  }

  &-profile {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #2d303e;
    padding-bottom: 35px;

    &_theme_center {
      align-items: center;
      justify-content: flex-start;
    }

    &_type_password {
      width: 100%;
    }

    &__content {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 45px 0;

      &_type_password {
        padding-top: 25px;
        width: 100%;
        max-width: 420px;
      }
    }

    &__title {
      color: #eaeaeb;
      font-size: 20px;
      line-height: 105%;
      letter-spacing: 0.02em;
      font-weight: 500;
      padding: 25px 0 30px 0;
      border-bottom: 1px solid #2d303e;
      width: 100%;
    }

    &__box-col {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      max-width: 520px;
      padding-left: 110px;
    }

    &__box-row {
      display: flex;
      width: 100%;
      padding: 35px 0 0 0;
    }



    &__button {
      display: flex;
      align-items: center;
      align-self: flex-end;
      justify-content: center;
      width: 230px;
      height: 46px;
      border-radius: 26px;
      font-size: 14px;
      font-weight: 600;
      line-height: 115%;
      letter-spacing: 0.03em;
      text-align: center;
      background-color: #6969df;
      color: #e1e1ed;
      border: none;
      cursor: pointer;
      margin-top: 45px;
      transition: opacity 0.4s linear;

      &_type_disabled {
        background-color: #696b73;
      }

      &_theme_center {
        align-self: center;
      }

      &:hover {
        opacity: 0.7;
      }
    }


    &__verify {
      display: flex;
      align-items: center;
      width: max-content;
      height: 20px;
      padding: 0 0 0 30px;
      background-position: left;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
    }

  }

  &_type_contact {
    max-width: 500px;
    min-width: 400px;
    min-height: 550px;
  }

  &__title {
    color: #000;
    font-size: 24px;
    line-height: 108%;
    letter-spacing: 0.03em;
    font-weight: 600;
    margin: 0 0 50px 0;
    text-align: center;

    &_theme_white {
      color: #fff;
    }
  }

  &-terms__link {
    padding-left: 3px;
  }

  &__check {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  &__terms {
    color: #3456F3;
  }

  &__link {
    text-decoration: none;
    color: #A0A0A0;
    transition: opacity 0.4s linear;

    &:hover {
      opacity: 0.6;
    }
  }

  &__subtitle {
    margin: 0;
    color: #000;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }

  &__span {
    margin-left: 6px;
    color: #3456F3;
  }

  &__button {
    cursor: pointer;
    color: #000;
    height: 45px;
    border: none;
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
    margin-top: 34px;
    transition: opacity 0.4s, background-color 0.4s linear;

    &_type_contact {
      color: #fff;
      background-color: transparent;
      border: 1px solid #BDBDBD;
      border-radius: 8px;
    }

    &_type_login {
      margin-top: 134px;
    }

    &_type_active {
      color: #fff;
      background-color: #3456F3;
    }

    &_type_disabled {
      background-color: #636363;
    }

    &:hover {
      opacity: 0.8;
    }

    &_type_signin {
      margin-top: 203px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .form-profile {
    &__box-col {
      max-width: 520px;
      padding-left: 50px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .form-profile {
    &__content {
      flex-direction: column;
      align-items: center;
    }

    &__box-col {
      max-width: 100%;
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .form_type_contact {
    max-width: 400px;
    min-width: 400px;
    padding-left: 35px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 950px) {
  .form_type_contact {
    max-width: 600px;
    min-width: 400px;
    padding-left: 0;
    padding-right: 0;
  }

  .form-profile {
    &__box-row {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 25px 0 0 0;
    }

  }
}

@media screen and (max-width: 500px) {
  .form_type_contact {
    padding: 50px 0;
    width: 100%;
    min-width: calc(100% - 20px);
  }
}

@media screen and (max-width: 480px) {
  .form__button {
    font-size: 12px;
    line-height: 15px;
  }

  .form__subtitle {
    font-size: 12px;
    line-height: 15px;
  }

  .form__check {
    align-items: flex-start;
  }

  .form-profile__button {
    align-self: center;
  }
}

@media screen and (max-width: 440px) {
  .form__label {}
}