@-webkit-keyframes spin7 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin7 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.preloader {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;

  &__nest {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 50px;
    margin: -25px 0 0 -25px;
    border: 2px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    -webkit-animation: spin7 1.5s ease infinite;
    animation: spin7 1.5s ease infinite;

    &:before {
      content: "";
      position: absolute;
      top: 7px;
      right: 7px;
      bottom: 7px;
      left: 7px;
      border: 2px solid transparent;
      border-radius: 50%;
      border-top-color: #ffffff;
      -webkit-animation: spin7 3s linear infinite;
      animation: spin7 3s linear infinite;
    }

    &:after {
      content: "";
      position: absolute;
      top: 15px;
      right: 15px;
      bottom: 15px;
      left: 15px;
      border: 2px solid transparent;
      border-radius: 50%;
      border-top-color: #ffffff;
      -webkit-animation: spin7 1.5s ease infinite;
      animation: spin7 1.5s ease infinite;
    }
  }
}