.module-facts {
  width: 100%;
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
  --swiper-pagination-color: #fff;
  --swiper-theme-color: #fff;

  &__slide {
    width: 310px;
    padding: 35px 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  &__image {
    width: 90px;
    height: 90px;
    object-fit: contain;
    margin-bottom: 22px;
  }

  &__title {
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: 0.03em;
    text-align: center;
  }
}

.swiper-wrapper {
  justify-content: space-between;
}