.footer {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  color: #000;
  flex-shrink: 0;

  &__container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    max-width: calc(1640px - 100px);
    padding: 30px 50px 15px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #000;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    color: #FEAE62;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 600;
    line-height: 110%;
  }

  &__subtitle {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    padding: 10px 0 10px;
    cursor: pointer;
    transition: color 0.4s linear;

    &:hover {
      color: rgba(24, 24, 24, 0.6);
      color: linear-gradient(189deg, rgba(255, 181, 112, 1) 0%, rgba(233, 236, 240, 1) 28%, rgba(162, 164, 167, 1) 63%, rgba(255, 255, 255, 1) 100%);
    }
  }

  &__terms {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 103%;
    color: rgba(10, 10, 10, 0.4);
    transition: color 0.4s linear;

    &:hover {
      color: rgba(24, 24, 24, 0.6);
      color: linear-gradient(189deg, rgba(255, 181, 112, 1) 0%, rgba(233, 236, 240, 1) 28%, rgba(162, 164, 167, 1) 63%, rgba(255, 255, 255, 1) 100%);
    }
  }

  &__copyright {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 103%;
    color: rgba(10, 10, 10, 0.4);
  }

  &__link {
    display: flex;
    align-items: flex-end;
  }

  &__social_icon {
    height: 20px;
    width: 20px;
    object-fit: contain;
    padding: 10px 10px 10px 0;
  }

}

@media screen and (max-width: 900px) {
  .footer__container {
    width: calc(100% - 25px);
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 700px) {
  .footer__content {
    align-items: center;
    flex-direction: column;
  }

  .footer__content_theme_line {
    flex-direction: column-reverse;
  }

  .footer__title {
    text-align: center;
    margin-top: 25px;
  }

  .footer__link {
    justify-content: center;
  }

  .footer__copyright {
    text-align: center;
    width: 100%;
    max-width: 90%;
    margin-top: 15px;
  }
}

@media screen and (max-width: 520px) {
  .footer__copyright {
    max-width: 250px;
    font-size: 12px;
  }

  .footer__terms {
    font-size: 12px;
  }
}