.terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: decimal;
  color: #000;
  max-width: 900px;

  &__list {
    list-style: decimal;
    color: #000;
    padding: 20px 0;
  }

  &__list-element {
    margin-left: 25px;
  }

  &-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: -webkit-linear-gradient(140deg, rgb(242, 238, 246) 50%, rgb(255, 255, 255) 13%);
    background: -moz-linear-gradient(140deg, rgb(242, 238, 246) 50%, rgb(255, 255, 255) 13%);
    background: linear-gradient(140deg, rgb(242, 238, 246) 50%, rgb(255, 255, 255) 13%);
    width: calc(100% - 100px);
    height: calc(100vh - 135px);
    max-height: 980px;
    padding: 25px 50px 110px;

    &__head {
      display: flex;
      width: 100%;
      max-width: 1940px;
      justify-content: space-between;
    }

    &__list {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1940px;
    }

    &__title {
      font-size: 36px;
      line-height: 120%;
      padding: 0 0 50px 0;
      letter-spacing: 0.03em;
      font-weight: 600;
      color: #000;
      max-width: 80%;
    }

    &__subtitle {
      color: #000;
      font-size: 18px;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.04em;
      padding: 5px 0;
      width: 100%;
      max-width: 1150px;
    }
  }

  &__title {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 110%;
    width: calc(100% - 20px);
    padding: 10px 10px 20px;
  }

  &__list-element {
    list-style: decimal;
    color: #000;
  }

  &__paragrafs {
    display: flex;
    flex-direction: column;
    list-style: disc;
    color: #000;
    padding: 10px 10px;
  }

  &__paragraf {
    margin-left: 35px;
    list-style: disc;
    color: #000;

    &-title {
      width: calc(100% - 20px);
      padding: 0 10px;
      font-weight: 500;
      letter-spacing: -0.04em;
      line-height: 110%;
      color: rgb(19, 18, 18);
    }
  }

  &__subtitle {
    color: rgb(19, 18, 18);
    width: 100%;
    max-width: 900px;
    width: calc(100% - 20px);
    padding: 0 10px 0 10px;
    font-weight: 500;
    padding-top: 10px;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 110%;
  }
}

@media screen and (max-width: 1800px) {
  .terms-preview__list {
    max-width: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .terms-preview__subtitle {
    max-width: 100%;
    font-size: 14px;
  }

  .terms-preview__title {
    font-size: 26px;
    padding-bottom: 20px;
  }

  .terms__subtitle {
    font-size: 14px;
  }

  .terms__title {
    font-size: 18px;
  }
}

@media screen and (max-width: 810px) {
  .terms-preview {
    width: calc(100% - 40px);
    height: calc(600px - 85px);
    max-height: 980px;
    padding: 25px 20px 60px;
  }
}

@media screen and (max-width: 600px) {
  .terms-preview {
    width: calc(100% - 40px);
    height: calc(700px - 85px);
    max-height: 980px;
    padding: 25px 20px 60px;
  }
}


@media screen and (max-width: 490px) {
  .terms-preview__title {
    font-size: 16px;
    padding-bottom: 20px;
  }

  .terms-preview__subtitle {
    font-size: 12px;
  }

  .terms__title {
    font-size: 16px;
  }

  .terms__subtitle {
    font-size: 12px;
  }
}