@import './vendor/normalize.css';
@import './fonts/fonts.scss';

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
ol,
a {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  color: #fff;
}

.body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.5);
}