.about {

  &__title {
    width: 80%;
    font-size: 32px;
    line-height: 105%;
    font-weight: 500;
    letter-spacing: 0.03em;
    text-align: center;
    color: #FEAE62;
    padding-bottom: 37px;
    border-bottom: 1px solid rgba(225, 225, 225, 0.5);
  }
}

.facts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 60px);
  padding: 0 30px;

  &__group {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 320px));
    justify-content: center;
    grid-column-gap: 30px;
    grid-row-gap: 56px;
    margin: 60px 0;
  }
}

.fact-main {
  display: flex;
  align-items: center;
  padding: 20px 20px 50px;
  width: calc(100% - 380px);
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);
}

.fact {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    width: 70px;
    height: 70px;
    object-fit: cover;

    &_type_main {
      width: 80px;
      height: 80px;
      margin-right: 60px;
    }
  }

  &__description {
    margin-top: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.03em;
    text-align: center;
    color: rgba(255, 255, 255, 0.843);

    &_type_main {
      color: #fff;
      text-align: left;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.05em;
    }
  }


}

@media screen and (max-width: 1200px) {
  .about {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .fact-main {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 710px) {
  .about__title {
    font-size: 24px;
  }

  .fact__description_type_main {
    font-size: 14px;
  }

  .fact__description {
    font-size: 12px;
  }

  .fact__image {
    width: 55px;
    height: 55px;
  }

  .fact__image_type_main {
    width: 65px;
    height: 65px;
    margin-right: 25px;
  }
}

@media screen and (max-width: 500px) {
  .fact-main {
    flex-direction: column;
    max-width: 320px;
  }

  .fact__description_type_main {
    text-align: center;
  }

  .fact__image_type_main {
    margin-right: 0;
  }

  .facts {
    width: calc(100% - 20px);
    padding: 0 10px;
  }
}

@media screen and (max-width: 320px) {
  .facts {
    width: 100%;
    padding: 0;
  }

  .facts__group {
    grid-template-columns: repeat(auto-fit, minmax(250px, 270px));
  }
}