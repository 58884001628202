.switch-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 7px;
  position: relative;
  border-radius: 7px;
  font-weight: 500;
  line-height: 20px;
  width: calc(85px - 20px);
  text-transform: uppercase;
  background: transparent;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  line-height: 110%;
  border: 1px solid #BDBDBD;
  z-index: 10;
  transition: all 0.4s linear;

  &:focus {
    outline: none;
  }

  &__lang {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #fff;
    position: relative;

    &_type_black {
      color: #000;
    }
  }

  &__arrow {
    width: 15px;
    height: 15px;
    position: relative;
    border-left: 1px solid #BDBDBD;
    padding: 0 5px 0 8px;
    transition: all 0.3s linear;

    &_type_black {
      border-color: #000;
    }

    &_line {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 1px;
      background-color: #efefef;
      display: inline-block;
      transition: all 0.2s ease;

      &_theme_black {
        background-color: #000;
      }

      &:first-of-type {
        border-radius: 0 0 3px 0;
        left: 8px;
        transform: rotate(45deg);
      }

      &:last-of-type {
        right: 5px;
        transform: rotate(-45deg);
      }
    }
  }

  &:hover &__arrow {
    transform: scale(1.01);
  }
}

.switch-button__list {
  z-index: 20;
  display: none;
  width: 100%;
  min-width: 72px;
  position: absolute;
  transform: translate(50%, 100%);
  bottom: 0;
  left: -50%;
  background-color: rgb(255, 255, 255);
  border-radius: 0 0 7px 7px;

  &_type_black {
    color: #fff;
    background-color: #efefef;
  }

  &_item {
    padding: 5px 9px;
    display: flex;
    color: #000;

    &:hover {
      background-color: rgb(207, 206, 206);
      border-radius: 0 0 7px 7px;
    }
  }
}

.switch-button__list_type_open {
  display: flex;
  flex-direction: column;
}