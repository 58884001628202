.menu {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out 0s;
  cursor: pointer;
  z-index: 999;

  .menu__lens {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 98;
    filter: blur(1px);
  }

  &__lens-content {
    height: 100vh;
    background-color: rgb(255, 255, 255);
    width: 70%;
    max-width: 360px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 99;
    width: 100%;
    height: 100%;
    max-height: 700px;
    background-color: rgb(255, 255, 255);
    position: relative;

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 0.8rem 3rem 0.8rem;
    }

    &-middle {
    }

    &-bottom {
      display: flex;
      justify-content: center;
    }
  }
}

.menu_type_visible {
  transform: translateX(0);
}

.menu_type_hidden {
  transform: translateX(-100%);
}

@media screen and (max-width: 400px) {
  .menu__content {
    width: 100%;
    max-width: 100%;
  }
}
