.info {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;

    &_type_head {
      align-items: center;
    }

    &_type_mid {
      padding-left: 0;
      padding-right: 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &_type_bottom {
      flex-direction: row;
      align-items: space-beetwen;
    }
  }

  &__box-map {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__content_box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 15px;
    height: 100%;
  }

  &__title {
    padding: 0 0 15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 105%;
    letter-spacing: 0.02em;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 15px;
    line-height: 108%;
    letter-spacing: 0.01em;
  }

  &__location {
    font-weight: 400;
    font-size: 15px;
    line-height: 110%;
    letter-spacing: 0.01em;
    padding: 5px 0;
  }

  &__link-location {
    display: flex;
    width: 40px;
    height: 40px;
    margin: 0 20px 0 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    transition: opacity 0.4s linear;

    &:hover {
      opacity: 0.6;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-bottom: 24px;
  }

  &__link {
    font-size: 15px;
    font-weight: 500;
    line-height: 108%;
    letter-spacing: 0.02em;

    transition: opacity 0.4s linear;

    &:hover {
      opacity: 0.6;
    }
  }

  &__line {
    width: 40%;
    height: 1px;
    border: none;
    background-color: #FFB570;
    margin: 12px 0;
    border-radius: 18px;
  }
}

@media screen and (max-width: 500px) {
  .info__title {
    font-size: 16px;
  }

  .info__subtitle {
    font-size: 14px;
  }

  .info__icon {
    width: 20px;
    height: 20px;
  }

  .info__location {
    font-size: 14px;
  }

  .info__link {
    font-size: 14px;
  }

  .info__link-location {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 400px) {
  .info__content_type_bottom {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  .info__content_type_mid {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  }

  .info__link-location {
    margin-right: 0;
  }

  .info__box-map {
    align-items: center;
  }
}